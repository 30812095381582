import cxs from 'cxs/component'

export default cxs('a')({
  fontFamily: 'Sarabun',
  fontSize: '1.5em',
  color: '#018abd',
  '@media screen and (max-width: 40em)': {
    fontSize: '1em',
  },
  '::selection': {
    backgroundColor: 'rgba(151, 203, 220,0.5)',
  },
  transition: 'all 0.2s',
  animation: 'fadein 0.2s',
  lineHeight: 1,
  margin: '0 8px',
  padding: 0,
})

import React from 'react'
import cxs from 'cxs/component'
import { motion } from 'framer-motion'

const SunLogo = cxs('div')({
  backgroundColor: '#FDB813',
  width: '120px',
  height: '120px',
  borderRadius: '60px',
})
const SunWrapper = cxs('div')({
  position: 'absolute',
  left: '10%',
  top: '3%',
})

export default () => (
  <SunWrapper>
    <motion.div
      initial={{ y: -150 }}
      animate={{ y: 0 }}
      transition={{
        delay: 2,
        duration: 0.1,
        type: 'spring',
        stiffness: 100,
      }}
    >
      <SunLogo />
    </motion.div>
  </SunWrapper>
)

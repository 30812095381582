import cxs from 'cxs/component'

export default cxs('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '4',
})

import React from 'react'
import { motion } from 'framer-motion'
import cxs from 'cxs/component'
import Wave1 from './Wave1.inline.svg'

const PositionWrapper = cxs('div')({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '95px',
  overflow: 'hidden',
})

const Wave1SVG = cxs(Wave1)({
  position: 'relative',
  width: '100%',
  height: '15vh',
  marginBottom: '-7px',
  /*Fix for safari gap*/
  minHeight: '100px',
  maxHeight: '150px',
  zIndex: '3',
})

export default () => (
  <PositionWrapper>
    <motion.div
      initial={{ y: 150 }}
      animate={{ y: 0 }}
      transition={{
        delay: 2,
        duration: 0.1,
        type: 'spring',
        stiffness: 100,
      }}
    >
      <Wave1SVG />
    </motion.div>
  </PositionWrapper>
)
